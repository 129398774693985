import "./landing.styles.css";

import { Link } from "react-router-dom";

import Navbar from "../../components/navbar/navbar.component";

const Landing = () => (
  <div className="landing">
    <Navbar className="landing-nav" />
    <div className="container">
      <div className="row">
        <div className="landing-con">
          <div className="col-md-7 col-12">
            <p className="headline">LTMS - Lisheen Technical & Mining ​Services</p>
            <p className="sub-headline">LTMS is an Irish based mining services and training provider serving the global mining and quarrying industries.</p>
            <div className="mobile-center">
              <Link to="/contact" className="btn btn-landing-contact">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-5 col-12"></div>
      </div>
    </div>
  </div>
);

export default Landing;
