import "./home-page.styles.css";

import Landing from "../../components/landing/landing.component";
import HomeAbout from "../../components/home-about/home-about.component";
// import News from "../../components/news/news.component";
import HomeServices from "../../components/home-services/home-services.component";

const HomePage = () => (
  <div>
    <Landing />
    <HomeAbout />
    <HomeServices />
    {/* <News /> */}
  </div>
);

export default HomePage;
