import JoeBurke from "../../../assets/management/joe.png";
import Navbar from "../../../components/navbar/navbar.component";

import "../management-profile.styles.css";

const JoeBurkePage = () => (
  <div className="page-height">
    <Navbar />
    <div className="management-profile">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-12">
            <img className="img-fluid management-profile-img" src={JoeBurke} alt="Joe Burke" />
          </div>
          <div className="col-md-9 col-12">
            <p className="name">Joe Burke</p>
            <p className="job">Associate Principal Geotechnical Engineer</p>
            <p className="sub-title">Qualifications and Professional Affiliations</p>
            <div className="line"></div>
            <p>Mining Diploma - Athlone Institute of Technology - 1972</p>
            <p>Diploma in Eng. Geology - British Institute of Engineering Technology 1976</p>
            <p>Rock Mechanics Certificate – South Africa Chamber of Mines 1984</p>
            <p>Advanced Rock Mechanics - University of the Witswaterand 1987</p>
            <p>Graduate Diploma in Engineering - University of the Witswaterand 1992</p>
            <p>Fellow of the South African Institute of Mining and Metallurgy</p>
            <p>Member of the International Society of Rock Mechanics</p>
            <p>Member of the Irish Mining and Quarrying Society</p>
          </div>
        </div>
        <p className="sub-title">Expertise</p>
        <div className="alt-line"></div>
        <p>Joe has primary expertise in rock mechanics including ground support and rock reinforcement design, rockmass characterization, ground behaviour monitoring and understanding, mining method selection, excavation design and extraction sequencing. </p>
        <p>Other key areas of expertise are development and management of geotechnical systems fit for purpose, prevention of fall of ground programs, identification and implementation of new technology and the application of risk management to geotechnical problems. Joe has worked on LTMS projects in Ireland, India, Kazakhstan and Zambia, where his geotechnical expertise has been used for mine design evaluation, operational reviews, training and preparation of ground control documentation.</p>
      </div>
    </div>
  </div>
);

export default JoeBurkePage;
