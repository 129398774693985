import TomFitzpatrick from "../../../assets/management/tom.png";
import Navbar from "../../../components/navbar/navbar.component";

import "../management-profile.styles.css";

const TomFitzpatrickPage = () => (
  <div className="page-height">
    <Navbar />
    <div className="management-profile">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-12">
            <img
              className="img-fluid management-profile-img"
              src={TomFitzpatrick}
              alt="Joe Burke"
            />
          </div>
          <div className="col-md-9 col-12">
            <p className="name">Tom Fitzpatrick</p>
            <p className="job">Specialist Trainer</p>
            <p className="sub-title">
              Qualifications and Professional Affiliations
            </p>
            <div className="line"></div>
            <p>Certified as a Mine Supervisor</p>
            <p>Certified in Train the Trainer</p>
            <p>Shotfirer for ANFO and Emulsion products</p>
            <p>
              Proficient on Jumbo, Bolter, LHD, Scaler, Truck, Excavator,
              Telescopic Handler and mine utility vehicles
            </p>
          </div>
        </div>
        <p className="sub-title">Expertise</p>
        <div className="alt-line"></div>
        <p>
          Tom is a highly experienced miner, supervisor and training specialist
          with more than 30 years in the mining industry. Tom has international
          experience in Zambia, India, Canada and UK following 20 years at
          Lisheen Mine in Ireland. Tom is Train the Trainer qualified, a jumbo
          drill operator and holds shotfiring tickets for both ANFO and
          emulsion. Tom specialises in hands-on training on all aspects of the
          development drill & blast cycle and is specialised in development
          through very poor ground conditions
        </p>
      </div>
    </div>
  </div>
);

export default TomFitzpatrickPage;
