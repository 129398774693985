import "./gallery-page.styles.css";

import { SRLWrapper } from "simple-react-lightbox";

import GalleryImage from "../../components/gallery-image/gallery-image.component";
import Navbar from "../../components/navbar/navbar.component";

import Image1 from "../../assets/gallery/1.png";
import Image2 from "../../assets/gallery/2.png";
import Image3 from "../../assets/gallery/3.png";
import Image4 from "../../assets/gallery/4.png";
import Image5 from "../../assets/gallery/5.png";
import Image6 from "../../assets/gallery/6.png";
import Image7 from "../../assets/gallery/7.png";
import Image8 from "../../assets/gallery/8.png";
import Image9 from "../../assets/gallery/9.png";
import Image10 from "../../assets/gallery/10.png";
import Image11 from "../../assets/gallery/11.png";

const GalleryPage = () => (
  <div>
    <Navbar />
    <div className="gallery">
      <SRLWrapper>
        <div className="container">
          <p className="gallery-headline">Check out our Gallery!</p>
          <div className="row">
            <GalleryImage
              imgUrl={Image1}
              caption="View at Black Angel Mine in Greenland"
            />
            <GalleryImage
              imgUrl={Image2}
              caption="Ekati Diamond Mine in Northern Canada"
            />
            <GalleryImage
              imgUrl={Image3}
              caption="Ekati Diamond Mine in Northern Canada"
            />
            <GalleryImage
              imgUrl={Image4}
              caption="Galantas – Cavancaw mine – Portal"
            />
            <GalleryImage
              imgUrl={Image5}
              caption="Views at Black Angel Mine in Greenland"
            />
            <GalleryImage imgUrl={Image6} caption="Sasa Mine in Macedonia" />
            <GalleryImage
              imgUrl={Image7}
              caption="Galantas – Cavancaw mine – Underground"
            />
            <GalleryImage
              imgUrl={Image8}
              caption="Brendan Morris with Mine Management at Shalkiya Mine, Kazakhstan"
            />
            <GalleryImage
              imgUrl={Image10}
              caption="LTMS at Mineração Caraíba MineComplex, Brazil"
            />
            <GalleryImage
              imgUrl={Image9}
              caption="LTMS Trainers with local miners in Siberia"
            />
            <GalleryImage
              imgUrl={Image11}
              caption="LTMS delivering Mine Supervisor Training at British Gypsum"
            />
          </div>
        </div>
      </SRLWrapper>
    </div>
  </div>
);

export default GalleryPage;
