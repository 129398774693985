import "./key-associates-page.styles.css";

import Navbar from "../../components/navbar/navbar.component";

const KeyAssociatesPage = () => (
  <div>
    <Navbar />
    <div className="key-associates">
      <div className="container">
        <p className="title">Trainers:</p>
        <p>Including miners and supervisors who have extensive expertise and experience in the use and training on a variety of mining equipment, including development drilling, production drilling, LHD, ground support installation, shotcreting. We provide also training to Mine Supervisors and Technical staff.</p>

        <p className="title">Geotechnical Engineer:</p>
        <p>With extensive international experience in underground & open pit mining and quarrying.</p>

        <p className="title">Environmental and Mine Closure Expert:</p>
        <p>With extensive experience in mining and working with regulators.</p>

        <p className="title">Mine Planning Engineers:</p>
        <p>With competence in long and short-term planning and scheduling, including ‘competent person’ compliance for Ore Reserve sign off.</p>

        <p className="title">Geologists:</p>
        <p>With experience in mine geology, resource geology and ‘competent person’ for Resource Estimation.</p>

        <p className="title">Metallurgy and Mineral Processing Expert:</p>
        <p>With a range of engineering and management experience.</p>

        <p className="title">Human Resources Manager:</p>
        <p>Experienced HR Manager with executive coaching qualifications.</p>
      </div>
    </div>
  </div>
);

export default KeyAssociatesPage;
