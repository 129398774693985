import "./navbar.styles.css";

import { HashLink } from "react-router-hash-link";

import { Link } from "react-router-dom";

import Logo from "../../assets/logo.webp";

const Navbar = () => (
  <nav className="navbar navbar-expand-lg navbar-dark">
    <div className="container">
      <Link className="navbar-brand" to="/">
        <img src={Logo} style={{ width: "190px" }} alt="Logo" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <HashLink className="nav-link" to="/">
              Home
            </HashLink>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/ltms-team">
              LTMS Team
            </Link>
          </li>
          {/* <li className="nav-item dropdown">
            <div className="dropdown">
              <div className="nav-link link">About</div>
              <div className="dropdown-content">
                <Link to="/management">Management</Link>
                <Link to="/key-associates">Key Associates</Link>
              </div>
            </div>
          </li> */}
          <li className="nav-item">
            <HashLink className="nav-link" to="/#services">
              Services
            </HashLink>
          </li>
          {/* <li className="nav-item">
            <HashLink className="nav-link" id="news-id" to="/#news">
              News
            </HashLink>
          </li> */}
          <li className="nav-item">
            <Link className="nav-link" to="/contact">
              Contact
            </Link>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://docs.google.com/document/d/16eJNsFZ9shG1lzx-xk0JGUUINtxsFh_0/edit?usp=sharing&ouid=112382793127790713260&rtpof=true&sd=true"
              target="_blank"
              rel="noreferrer">
              Projects
            </a>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/gallery">
              Gallery
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);

export default Navbar;
