import "../services.styles.css";
import "./technical.styles.css";

import TechnicalImage from "../../../assets/services/1.png";
import Navbar from "../../../components/navbar/navbar.component";

const TechnicalPage = () => (
  <div>
    <Navbar />
    <div className="service-page">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-12">
            <img
              className="img-fluid"
              src={TechnicalImage}
              alt="Technical & Operational Services"
              style={{ borderRadius: "0" }}
            />
          </div>
          <div className="col-md-7 col-12">
            <p className="title">Technical & Operational Services</p>
            <p>
              LTMS offers a range of services in mining and quarrying, including
              the following:
            </p>
            <p>• Due Diligence</p>
            <p>• Independent Reviews</p>
            <p>• Geotechnical Engineering</p>
          </div>
        </div>

        <p className="mt-3">
          • Drill & Blast Engineering, with specialty in longhole open stoping
          and development mining
        </p>
        <p>
          • Drill & Blast Engineering for open pits, quarries and civils
          projects
        </p>
        <p>• Operational & Safety Assessments</p>
        <p>• Organisational Management</p>
        <p>• Provision of short-term management and supervision</p>
        <p>• Safety Management</p>
        <p>• Preparation of mining documentation and procedures</p>
        <p>
          • Recruitment of mining personnel – engineers, geologists, mine
          supervisors, miners, etc
        </p>
      </div>
    </div>
  </div>
);

export default TechnicalPage;
