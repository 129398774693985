import "./contact-page.styles.css";

import Navbar from "../../components/navbar/navbar.component";

const ContactPage = () => (
  <div className="page-height">
    <Navbar />
    <div className="contact-form">
      <div className="container">
        <p className="text-center" style={{ fontFamily: "Roboto", fontSize: "17px" }}>
          Have a question? Just ask!
        </p>
        <div className="row">
          <div className="col-12 col-md-6">
            <form onSubmit="submit" name="contact v1" method="POST" data-netlify="true">
              <input type="hidden" name="form-name" value="contact v1" />
              <div className="form-group">
                <input name="name" type="text" className="form-control" id="name" aria-describedby="emailHelp" placeholder="Name" required />
              </div>
              <div className="form-group">
                <input name="email" type="email" className="form-control" id="email" placeholder="Email" required />
              </div>
              <div className="form-group">
                <input name="phone" type="tel" className="form-control" id="telephone" placeholder="Number" required />
              </div>
              <div className="form-group">
                <textarea name="message" className="form-control" id="Message" placeholder="Message" rows="6" required></textarea>
              </div>
              <button type="submit" className="btn btn-primary btn-form mb-5 mb-md-0">
                Submit
              </button>
            </form>
          </div>
          <div className="col-12 col-md-6">
            <div className="z-depth-1-half map-container" style={{ height: "400px" }}>
              <iframe title="LMTS" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2438073.5926867034!2d-10.341617252412433!3d53.36238542783221!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x485e619e5d73698f%3A0xca9b39444d6ac68d!2sIreland!5e0!3m2!1sen!2sie!4v1615854899690!5m2!1sen!2sie" width="600" height="450" frameBorder="0" style={{ border: "0" }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ContactPage;
