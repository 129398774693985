import "../services.styles.css";
import "./training.styles.css";

import TrainingImage from "../../../assets/services/2.png";
import Navbar from "../../../components/navbar/navbar.component";

const TrainingPage = () => (
  <div>
    <Navbar />

    <div className="service-page">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-12">
            <img
              className="img-fluid"
              src={TrainingImage}
              alt="Training of Mining and Technical Personnel"
            />
          </div>
          <div className="col-md-7 col-12">
            <p className="title">Training</p>
            <p className="title">Training of Mining and Technical Personnel</p>
            <p>
              LTMS offers a series of training courses, accredited through
              Minerals Products Qualifications Council (MPQC), a UK regulated
              body that sets and maintains standards in related industries,
              including mining and quarrying.
            </p>
          </div>
        </div>
        <p className="title pt-3">MPQC accredited courses are as below:</p>
        <p>• Mine Supervisor Training (3-day course)</p>
        <p>
          • Introduction to Mining (1-day course for non-mining personnel
          (geologists, environmentalists, accountants, administration, etc)
        </p>
        <p>• Development drilling and charging</p>
        <p>• LHD operation – manual and remote control</p>
        <p>• Truck Driving</p>
        <p>• Services installation</p>
        <p>• Shotcreting</p>

        <p className="title pt-1">Additional Courses include:</p>
        <p>• Ground Awareness – miners, supervisors and management</p>
        <p>• Underground drill and blast training for engineers</p>
        <p>• Surface drill and blast training for engineers</p>
        <p>• Advanced blast vibrations training</p>

        <p className="title pt-1">
          Other courses can be developed as required, including mining related
          and safety/risk management
        </p>
      </div>
    </div>
  </div>
);

export default TrainingPage;
