import "./management.styles.css";

import Profile from "../../components/profile/profile.component";
import Navbar from "../../components/navbar/navbar.component";

import Brendan from "../../assets/management/brendan.png";
import Joe from "../../assets/management/joe.png";
import Stephen from "../../assets/management/stephen.png";
import Nathan from "../../assets/management/nathan.png";
import Alan from "../../assets/management/alan.png";
import BrendanMockler from "../../assets/management/brendanMockler.png";
import Tom from "../../assets/management/tom.png";

const ManagementPage = () => (
  <div>
    <Navbar />
    <div className="management">
      <div className="container">
        <p>
          The LTMS Team have the experience, skills and enthusiasm to provide
          the leadership to manage the range of services offered by the company.
          We are committed to delivering our projects to a high standard of
          safety, within scope, on time and on budget.
        </p>
        <p className="info">
          Select one of the images below to find out more about each of our
          team.
        </p>
        <div className="row">
          <Profile
            link="brendan-morris"
            job="Managing Director"
            name="Brendan Morris"
            img={Brendan}
          />
          <Profile
            link="joe-burke"
            job="Associate Principal Geotechnical Engineer"
            name="Joe Burke"
            img={Joe}
          />
          <Profile
            link="stephen-wheston"
            job="Associate Principal Safety, Health and Environmental Specialist"
            name="Stephen Wheston"
            img={Stephen}
          />
          <Profile
            link="nathan-rouse"
            job="Associate Drill & Blast Specialist"
            name="Nathan Rouse"
            img={Nathan}
          />
          <Profile
            link="alan-dolan"
            job="Associate Mining Engineer (Drill & Blast and Ground Control)"
            name="Alan Dolan"
            img={Alan}
          />
          <Profile
            link="brendan-mockler"
            job="Specialist Trainer"
            name="Brendan Mockler"
            img={BrendanMockler}
          />
          <Profile
            link="tom-fitzpatrick"
            job="Specialist Trainer"
            name="Tom Fitzpatrick"
            img={Tom}
          />
        </div>
      </div>
    </div>
  </div>
);

export default ManagementPage;
