import "./home-about.styles.css";

import About from "../../assets/about.png";

const HomeAbout = () => (
  <div className="home-about">
    <div className="container">
      <div className="row">
        <div className="col-md-5 col-12">
          <img className="img-fluid about-img" src={About} alt="About" />
        </div>
        <div className="col-md-7 col-12">
          <p className="title pt-md-0 pt-3">About Us</p>
          <p className="vision">Our Vision</p>
          <p className="vision-text">
            Our vision is to be a leading world class mining services and
            training provider, who are the preferred partners for mining
            operations in their journey to becoming safer, more productive and
            cost effective.
          </p>
        </div>
      </div>
      <div className="mt-md-4 mt-0">
        <p>
          The company was established in February 2016, following the planned
          closure of the Lisheen Mine, for the purpose of retaining the
          collective expertise in mining, technical and related areas and
          utilising the processes and systems that have been developed and
          implemented by the mine since its inception in 1997.
        </p>
        <p>
          We understand how mining companies operate and the ongoing pressure to
          mine safely, reduce costs and increase productivity.
        </p>
        <p>
          LTMS has extensive experience at identifying operational, technical
          and financial bottlenecks and developing solutions for sustainable
          improvement.
        </p>
      </div>
    </div>
  </div>
);

export default HomeAbout;
