import "../services.styles.css";
import "./safety.styles.css";

import SafetyImage from "../../../assets/services/3.png";
import Navbar from "../../../components/navbar/navbar.component";

const SafetyPage = () => (
  <div>
    <Navbar />
    <div className="service-page">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-12">
            <img className="img-fluid" style={{ width: "100%" }} src={SafetyImage} alt="Safety, Environmental & Mine Closure" />
          </div>
          <div className="col-md-7 col-12">
            <p className="title">Safety, Environmental & Mine Closure</p>
            <p>• Safety - Assessment of organisational safety systems.</p>
            <p>• Safety Audits and Peer Reviews.</p>
            <p>• Development of safety documentation.</p>
            <p>• Safety Management and Training.</p>
            <p>• Environmental Assessment and Management.</p>
            <p>• Mine Closure Review and Management.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SafetyPage;
