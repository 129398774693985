import BrendanMockler from "../../../assets/management/brendanMockler.png";
import Navbar from "../../../components/navbar/navbar.component";

import "../management-profile.styles.css";

const BrendanMocklerPage = () => (
  <div className="page-height">
    <Navbar />
    <div className="management-profile">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-12">
            <img
              className="img-fluid management-profile-img"
              src={BrendanMockler}
              alt="Brendan Morris"
            />
          </div>
          <div className="col-md-9 col-12">
            <p className="name">Brendan Mockler</p>
            <p className="job">Specialist Trainer</p>
            <p className="sub-title">
              Qualifications and Professional Affiliations
            </p>
            <div className="line"></div>
            <p>Certified as a Mine Supervisor</p>
            <p>Certified in QQI Project Management</p>
            <p>Certified in Train the Trainer</p>
            <p>
              Instructor in manual handling, Abrasive wheel and working at
              heights
            </p>
            <p>Covid Compliance Officer</p>
            <p>Shotfirer for ANFO and Emulsion products</p>
            <p>
              Proficient on Jumbo, Scaler, Truck, Excavator, Telescopic Handler
              and mine utility vehicles
            </p>
          </div>
        </div>
        <p className="sub-title">Expertise</p>
        <div className="alt-line"></div>
        <p>
          Brendan is a highly experienced miner, supervisor, trainer and safety
          specialist with more than 30 years in the mining industry. Brendan has
          international experience in India, Egypt, UK and Portugal following 20
          years at Lisheen Mine in Ireland. Brendan is Train the Trainer
          qualified, a jumbo drill operator and holds shotfiring tickets for
          both ANFO and emulsion. Brendan specialises in operational
          organisation and the set-up and management of training systems.
        </p>
      </div>
    </div>
  </div>
);

export default BrendanMocklerPage;
