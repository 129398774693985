import Alan from "../../../assets/management/alan.png";
import Navbar from "../../../components/navbar/navbar.component";

import "../management-profile.styles.css";

const AlanDolanPage = () => (
  <div className="page-height">
    <Navbar />
    <div className="management-profile">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-12">
            <img
              className="img-fluid management-profile-img"
              src={Alan}
              alt="Alan Dolan"
            />
          </div>
          <div className="col-md-9 col-12">
            <p className="name">Alan Dolan</p>
            <p className="job">
              Associate Mining Engineer (Drill & Blast and Ground Control)
            </p>
            <p className="sub-title">
              Qualifications and Professional Affiliations
            </p>
            <div className="line"></div>
            <p>
              BEng (Honours) Mining - University of Exeter, Camborne School of
              Mines
            </p>
            <p>Associate Member of Camborne School of Mines (ACSM)</p>
            <p>Chartered Engineer, Engineers Ireland (CEng MIEI) – 2010</p>
            <p>
              President - Irish Mining and Quarrying Society (IMQS) - 2022 to
              present
            </p>
            <p>
              Irish representative and committee member of the European
              Federation of Explosives Engineers (EFEE) - 2012 to present
            </p>
          </div>
        </div>
        <p className="sub-title">Expertise</p>
        <div className="alt-line"></div>
        <p>
          Alan is a multi-skilled mining engineer with 30 years’ experience in
          the extractives industry, with specialist skills in Drill & Blast and
          Ground Control. Alan holds an Honours Degree in Mining Engineering
          from Camborne School of Mines and is a Chartered Engineer.
        </p>
        <p>
          Alan has worked internationally as a Blasting Engineer / Explosives
          Supervisor / Blasting Manager in quarries, open pit mines, civil
          engineering sites and road projects in Australia, West Africa (Mali
          and Burkina Faso), Canada and the UK. Alan has most recently been
          employed at Tara Mines in Ireland in roles including Underground Drill
          and Blast Engineer, Ground Control Engineer and Shiftboss.
        </p>
      </div>
    </div>
  </div>
);

export default AlanDolanPage;
