import "../services.styles.css";
import "./metallurgy.styles.css";

import MetallurgyImage from "../../../assets/services/4.png";
import Navbar from "../../../components/navbar/navbar.component";

const MetallurgyPage = () => (
  <div>
    <Navbar />
    <div className="service-page">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-12">
            <img className="img-fluid" src={MetallurgyImage} alt="Metallurgy and Minerals Processing" />
          </div>
          <div className="col-md-7 col-12">
            <p className="title">Metallurgy and Minerals Processing</p>
            <p>• Project and Feasibility Study Management and Development.</p>
            <p>• Mining, Process Operations and Infrastructure.</p>
            <p>• Process Design and Engineering.</p>
            <p>• Operational Support and Troubleshooting.</p>
            <p>• Due Diligence and Peer Reviews.</p>
            <p>• Competent Person Reporting.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MetallurgyPage;
