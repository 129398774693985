import "./footer.styles.css";

import GSI from "../../assets/footer/gsi.png";
import IMQS from "../../assets/footer/imqs.png";
import Logo from "../../assets/footer/ltms.png";

const Footer = () => (
  <div className="footer">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-12">
          <p>Business Address: 31 Gleann Na Riogh Court, Naas, Co. Kildare, Ireland.</p>
          <p>Telephone: +353 87 2241129</p>
          <p>Email: contact@ltms.ie</p>
        </div>
        <div className="col-md-6 col-12">
          <img src={GSI} alt="GSI" />
          <img src={IMQS} alt="IMQS" />
          <img src={Logo} alt="LTMS" />
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
