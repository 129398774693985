import "./gallery-image.styles.css";

const GalleryImage = (props) => (
  <div className="col-md-4 col-12">
    <a className="lightbox" href={props.imgUrl} data-caption={props.caption}>
      <img className="gallery-img img-fluid" src={props.imgUrl} alt={props.caption} />
    </a>
    <p className="gallery-text">{props.caption}</p>
  </div>
);

export default GalleryImage;
