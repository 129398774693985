import StephenWheston from "../../../assets/management/stephen.png";
import Navbar from "../../../components/navbar/navbar.component";

import "../management-profile.styles.css";

const StephenWhestonPage = () => (
  <div className="page-height">
    <Navbar />
    <div className="management-profile">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-12">
            <img className="img-fluid management-profile-img" src={StephenWheston} alt="Stephen Wheston" />
          </div>
          <div className="col-md-9 col-12">
            <p className="name">Stephen Wheston</p>
            <p className="job">Associate Principal Safety, Health and Environmental Specialist</p>
            <p className="sub-title">Qualifications and Training</p>
            <div className="line-training"></div>
            <p>Master’s in Environmental Science (MSc), Sligo IT, Ireland</p>
            <p>Higher Diploma Food Science (H Dip.) University College Cork, Ireland</p>
            <p>Bachelor of Science (BSc), University College Galway, Ireland</p>

            <p>Trained auditor for ISO 14001 & OHSAS 18001</p>
            <p>Trained on DNV- International Safety Rating System (ISRS)</p>
            <p>Trained on University of Queensland, Safety Risk Management Process (SRMP)</p>
            <p>Trained on Zero Incident Process (ZIP) Behavioural Based Safety Programme</p>
            <p>Units 1 to 3 (75%) of NEBOSH International Health and Safety Diploma completed</p>
          </div>
        </div>
        <p className="sub-title">Expertise</p>
        <div className="alt-line"></div>
        <p>Stephen has 20 years of experience in the management of Safety, Health and Environmental in the mining and chemical sector. After graduating Stephen spent six years working with ICI before joining the Lisheen Mine. Stephen held various roles at the Lisheen. Stephen was a member of the EXCO team and Safety Health and Environmental Manager for the operation for the last 5 years of operation and he took over full management of the site from June 2016 to oversee the implementation of the Mine Closure programme. As well as overseeing and auditing the safety performance of the Lisheen Site, Stephen has carried out performance reviews of other overseas mines operated by Anglo American and Vedanta.</p>
      </div>
    </div>
  </div>
);

export default StephenWhestonPage;
