import Nathan from "../../../assets/management/nathan.png";
import Navbar from "../../../components/navbar/navbar.component";

import "../management-profile.styles.css";

const NathanRousePage = () => (
  <div className="page-height">
    <Navbar />
    <div className="management-profile">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-12">
            <img
              className="img-fluid management-profile-img"
              src={Nathan}
              alt="Nathen Rouse"
            />
          </div>
          <div className="col-md-9 col-12">
            <p className="name">Nathan Rouse</p>
            <p className="job">Associate Drill & Blast Specialist</p>
            <p className="sub-title">
              Qualifications and Professional Affiliations
            </p>
            <div className="line"></div>
            <p>
              PhD Mining and Explosives Engineering, Missouri S&T, United States
            </p>
            <p>MS Explosives Engineering, Missouri S&T, United States</p>
            <p>
              BS Mining Engineering, University of Missouri - Rolla, United
              States
            </p>
            <p>Professional Engineer (PE), United States</p>
            <p>
              Member of International Society of Explosives Engineers (ISEE)
            </p>
            <p>
              Member of the European Federation of Explosives Engineers (EFEE)
            </p>
            <p>
              Member of the Society for Mining, Metallurgy, and Exploration
              (SME)
            </p>
          </div>
        </div>
        <p className="sub-title">Expertise</p>
        <div className="alt-line"></div>
        <p>
          Nathan’s expertise focuses on practical drill and blast engineering
          with a focus on bridging the gap between engineering teams and
          operations teams. He has experience with surface and underground
          mining and construction operations globally. Nathan focuses on
          instrumentation and root cause analysis, blast optimization and
          perimeter control, vibration monitoring and control, training, and
          expert witness work. He loves the opportunity to travel to new sites,
          meet new people, and work with new teams to improve their operational
          outcomes.
        </p>
      </div>
    </div>
  </div>
);

export default NathanRousePage;
