import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import "./index.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./utilities/scroll-to-top.component";

import Footer from "./components/footer/footer.component";

import HomePage from "./pages/home-page/home-page.component";
import ContactPage from "./pages/contact-page/contact-page.component";
import KeyAssociatesPage from "./pages/key-associates/key-associates-page.component";

import TechnicalPage from "./pages/services/technical/technical.component";
import TrainingPage from "./pages/services/training/training.component";
import SafetyPage from "./pages/services/safety/safety.component";
import MetallurgyPage from "./pages/services/metallurgy/metallurgy.component";

import ManagementPage from "./pages/management-page/management-page.component";
import GalleryPage from "./pages/gallery-page/gallery-page.component";

import BrendanMorrisPage from "./pages/management-profiles/brendan-morris-page/brendan-morris.componet";
import JoeBurkePage from "./pages/management-profiles/joe-burke-page/joe-burke.component";
import StephenWhestonPage from "./pages/management-profiles/stephen-wheston-page/stephen-wheston.component";
import NathanRousePage from "./pages/management-profiles/nathan-rouse-page/nathan-rouse.component";
import AlanDolanPage from "./pages/management-profiles/alan-dolan-page/alan-dolan.component";
import BrendanMocklerPage from "./pages/management-profiles/brendan-mockler-page/brendan-mockler.component";
import TomFitzpatrickPage from "./pages/management-profiles/tom-fitzpatrick-page/tom-fitzpatrick.component";

function App() {
  return (
    <SimpleReactLightbox>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/key-associates" component={KeyAssociatesPage} />
          <Route exact path="/technical" component={TechnicalPage} />
          <Route exact path="/training" component={TrainingPage} />
          <Route exact path="/safety" component={SafetyPage} />
          <Route exact path="/metallurgy" component={MetallurgyPage} />
          <Route exact path="/ltms-team" component={ManagementPage} />
          <Route exact path="/gallery" component={GalleryPage} />
          <Route exact path="/brendan-morris" component={BrendanMorrisPage} />
          <Route exact path="/joe-burke" component={JoeBurkePage} />
          <Route exact path="/stephen-wheston" component={StephenWhestonPage} />
          <Route exact path="/nathan-rouse" component={NathanRousePage} />
          <Route exact path="/alan-dolan" component={AlanDolanPage} />
          <Route exact path="/brendan-mockler" component={BrendanMocklerPage} />
          <Route exact path="/tom-fitzpatrick" component={TomFitzpatrickPage} />
        </Switch>
        <Footer />
      </Router>
    </SimpleReactLightbox>
  );
}

export default App;
