import "./profile.styles.css";

import { Link } from "react-router-dom";

const Profile = (props) => (
  <div className="col-md-3 col-12">
    <div className="profile">
      <Link to={props.link}>
        <img className="img-fluid management-img mx-auto d-block" src={props.img} alt="Profile" />
      </Link>
      <p className="management-name">{props.name}</p>
      <p className="management-job">{props.job}</p>
    </div>
  </div>
);

export default Profile;
