import BrendanMorris from "../../../assets/management/brendan.png";
import Navbar from "../../../components/navbar/navbar.component";

import "../management-profile.styles.css";

const BrendanMorrisPage = () => (
  <div className="page-height">
    <Navbar />
    <div className="management-profile">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-12">
            <img className="img-fluid management-profile-img" src={BrendanMorris} alt="Brendan Morris" />
          </div>
          <div className="col-md-9 col-12">
            <p className="name">Brendan Morris</p>
            <p className="job">Managing Director</p>
            <p className="sub-title">Qualifications and Professional Affiliations</p>
            <div className="line"></div>
            <p>Master of Business Practice (MSc), University College Cork, Ireland.</p>
            <p>Bachelor of Mining Engineering (BEng), Camborne School of Mines, University of Exeter, UK.</p>

            <p>a. Chartered Mining Engineer (CEng), IOM3</p>
            <p>b. Associate of Camborne School of Mines (ACSM)</p>
            <p>c. Member of the Institute of Materials, Minerals and Mining (MIMMM)</p>
            <p>d. President of Irish Mining and Quarrying Society (IMQS) – 2016 to 2018</p>
          </div>
        </div>
        <p className="sub-title">Expertise</p>
        <div className="alt-line"></div>
        <p>Brendan has primary expertise in international mining, quarrying and technical services management, having worked in Ireland, Canada, UK and United Arab Emirates. Brendan has held positions of Mine Manager, Technical Services Manager, Quarry Manager and Project Manager at various operations. Brendan has specific skills in mine production management and efficiency improvements and technical skills in mine planning, drill and blast design and surveying.</p>
        <p>While at Lisheen, Brendan has worked on optimisation projects at mining operations in India and Africa on behalf of Anglo American and Vedanta. With LTMS, Brendan has consulted at several mines in Russia, Kazakhstan, Greenland, UK, Canada, Africa and Ireland, carrying out due diligence, feasibility reviews and mine/technical audits, reviews and managing rehabilitation projects.</p>
      </div>
    </div>
  </div>
);

export default BrendanMorrisPage;
