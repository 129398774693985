import "./home-services.styles.css";

import HomeServicesBox from "../home-services-box/home-services-box.component";

const HomeServices = () => (
  <div className="home-services" id="services">
    <div className="container">
      <p className="services-headline">Services</p>
      <div className="row">
        <HomeServicesBox
          link="/technical"
          title="Technical & Operational Services"
        />
        <HomeServicesBox link="/training" title="Training" />

        <HomeServicesBox
          link="/safety"
          title="Safety, Environmental and Mine Closure"
        />

        <div class="col-md-6 col-lg-12 ">
          <div class="d-flex justify-content-center">
            <a
              class="btn btn-capability"
              href="https://drive.google.com/file/d/1BTWPQ7cf7ZYAE_38A_85csHwEmyJRDff/view?usp=drive_link"
              target="_blank"
              rel="noreferrer">
              Capability Statement
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HomeServices;
