import "./home-services-box.styles.css";

import { Link } from "react-router-dom";

const HomeServicesBox = (props) => (
  <div className="col-md-6 col-12">
    <div className="home-services-box">
      <Link to={props.link}>
        <div className="box">
          <p className="home-services-box-title">{props.title}</p>
        </div>
      </Link>
    </div>
  </div>
);

export default HomeServicesBox;
